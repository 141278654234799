import React from 'react'
import Av_Datapoints_Table from './../../components/Av_Datapoints_Table/index'

const Available_datapoints_table = () => {
  return (
    <div>
      <Av_Datapoints_Table />
    </div>
  )
}

export default Available_datapoints_table
