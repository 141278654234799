import React from 'react'
import SingleColumnDataCollection from '../../components/single-column-data-collection/SingleColDataCollection'
function Collections() {
  return (
    <>
        <SingleColumnDataCollection/>
    </>
  )
}

export default Collections