import React from 'react'
import AllUsersComp from '../../components/All-users-comp'

const AllUsers = () => {
  return (
    <div>
      <AllUsersComp />
    </div>
  )
}

export default AllUsers
