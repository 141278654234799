import React from 'react'
import All_User_Groups from '../../components/All-user-groups'

const AllUserGroups = () => {
  return (
    <div>
      <All_User_Groups />
    </div>
  )
}

export default AllUserGroups
