import React from 'react'
import NotificationComp from '../../components/notification-comp'

const Notifications = () => {
  return (
    <div>
      <NotificationComp />
    </div>
  )
}

export default Notifications
