import React from "react";
import Chartdashboard from "../../components/chart-dashboard/Chartdashboard";
function Dashboard() {
  return (
    <>
      <Chartdashboard />
    </>
  );
}

export default Dashboard;
